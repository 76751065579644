import React from 'react'
import './Contact.css';

const Contact = () => {
  return (
    <div className='Contact'>
      Contact
    </div>
  )
}

export default Contact
